// Deps
import request from 'utils/request'
import store from 'store'
import { pushMessage } from 'controllers/messenger'
// import config from 'config'

// Actions
import {
	setBasketContent,
} from "store/commerce/actions";

const commerceServices = {
	listBrands: (params= {}) => {
		let listParams = { page: 1, ...params};
		return new Promise((resolve, reject) => {
			request.post(
				'list-product-brends',
				listParams,
				(payload, status, request) => {
					if (status === 200 && payload) {
						resolve({
							brands: payload,
							pagination: request.data.paginate
						});
					}
					else {
						reject(false);
					}
				},
			);
		});
	},
	listCategories: (params= {}) => {
		let listParams = { page: 1, ...params};
		return new Promise((resolve, reject) => {
			request.post(
				'list-product-types',
				listParams,
				(payload, status, request) => {
					if (status === 200 && payload) {
						resolve({
							categories: payload,
							pagination: request.data.paginate
						});
					}
					else {
						reject(false);
					}
				},
			);
		});
	},
	listProducts: (params = {}) => {
		return new Promise((resolve, reject) => {
			request.post(
				'list-products',
				params,
				(payload, status, request) => {
					if(status === 200 && payload) {
						resolve({
							products: payload,
							pagination: request.data.paginate
						});
					}
					else {
						reject(false);
					}
				},
			);
		});
	},
	fetchProductsFromXml: (url) => {
		return new Promise((resolve, reject) => {
			request.post(
				'create-or-update-products',
				{xml_url: url},
				(payload, status, request) => {
					if(status === 200) {
						resolve();
					}
					else {
						reject();
					}
				}
			)
		});
	},
	fetchStoresFromXml: (url) => {
		return new Promise((resolve, reject) => {
			request.post(
				'create-or-update-group-xml',
				{xml_url: url},
				(payload, status, request) => {
					if(status === 200) {
						resolve();
					}
					else {
						reject();
					}
				}
			)
		});
	},
	testXmlUrl: (url) => {
		return new Promise((resolve, reject) => {
			request.get(
				url,
				false,
				(payload, status, request) => {
					
					if(status >= 200 && status <= 200) {
						resolve();
					}
					else {
						reject();
					}
				},
				{
					external: true
				}
			)
		});
	},
	getProduct: (id) => {
		return new Promise((resolve, reject) => {
			request.post(
				'get-product',
				{ product_id: id },
				(payload, status, request) => {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject();
					}
				}
			)
		});
	},
	// Basket Helpers
	processProduct: (product, userData = false) => {
		if(!userData) {
			userData = store.getState().user.data;
		}

		const storeStock = (userData.company.deliveryMethods.includes('1') && userData.default_group) ? product.store_stock.find(stock => stock.group.id === userData.default_group.id) : null;

		const internetStock = (userData.company.deliveryMethods.includes('2') && userData.company.home_delivery_store) ? product.store_stock.find(stock => stock.group.id === userData.company.home_delivery_store.id) : null;
		
		return {
			...product,
			stockInfo: {
				stocks: product.store_stock,
				userStore: storeStock === null ? false : (storeStock ? storeStock : {
					group: userData.default_group,
					stock: 0,
				}),
				internetStore: internetStock === null ? false : (internetStock ? internetStock : {
					group: userData.company.home_delivery_store,
					stock: 0,
				}),
			},
		}
	},
	parseBasket: () => {
		// const defaultReturn = [[], {}];
		const localStorageData = window.localStorage.getItem('basketContent');

		if(localStorageData !== null) {
			try {
				const basketContent = JSON.parse(localStorageData);
				return basketContent;
			}
			catch(e) {
				return {};
			}
		}
		else {
			return {};
		}
	},
	syncBasket: () => {
		return new Promise((resolve, reject) => {
			const curState = store.getState();

			let newBasketContent = {...curState.commerce.basketContent};

			let requestList = [];
			for(const productID in newBasketContent) {
				requestList.push(commerceServices.getProduct(productID));
			}

			Promise.all(requestList).then((payloads) => {				
				for(const productInfo of payloads) {
					if(productInfo) {
						newBasketContent[productInfo.id].product = commerceServices.processProduct(productInfo);
					}
				}

				store.dispatch(setBasketContent(newBasketContent));
				
				resolve(newBasketContent);
			})
		});
	},
	setBasketQuantity: (product, quantity) => {
		const curState = store.getState();
		let newBasketContent = {...curState.commerce.basketContent};

		if(quantity === 0) {
			if(newBasketContent[product.id]) {
				delete newBasketContent[product.id]; 
				pushMessage(`Ürün sepetten çıkarıldı: ${product.title}`, {});
			}
		}
		else {
			if(newBasketContent[product.id]) {
				pushMessage(`Ürün adedi güncellendi: ${product.title}`, {});
			}
			else {
				pushMessage(`Ürün sepete eklendi: ${product.title}`, {});
			}
			newBasketContent[product.id] = {
				product: product, 
				quantity: quantity,
			};
		}

		store.dispatch(setBasketContent(newBasketContent));
		localStorage.setItem('basketContent', JSON.stringify(newBasketContent));
	},
	createOrder: (params) => {
		return new Promise((resolve, reject) => {
			request.post(
				'express-order-with-products',
				params,
				(payload, status, request) => {
					if(status === 200 && request.data.status === "success") {
						resolve(payload);
					}
					else {
						reject(request);
					}
				}
			)
		})
	},
	previewRefund: (params) => {
		return new Promise((resolve, reject) => {
			request.post(
				'refund-payment-prova',
				params,
				(payload, status, request) => {
					if(status === 200 && request.data.status === "success") {
						resolve(payload);
					}
					else {
						reject(request);
					}
				}
			)
		})
	},
	clearBasket: () => {
		store.dispatch(setBasketContent({}));
		localStorage.removeItem('basketContent');
	}
}

export default commerceServices;